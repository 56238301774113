.contacttxtbox, .contacttxtarea {
  display: inline-block;
  width: 100%;
  // @include box-sizing(content-box);
  margin: 0 0 6px 0;
  padding: 6px 12px;
  border: 1px solid;
  border-radius: 3px;
  -o-text-overflow: clip;
  text-overflow: clip;
  // box-shadow: 2px 2px 2px 0 inset;
  // text-shadow: 0 0 0;
  // transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
}

.contactbutton {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  position: relative;
  cursor: pointer;
  margin: 0 0;
  padding: 6px 12px;
  border: 1px solid;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -o-text-overflow: clip;
  text-overflow: clip;
  // -webkit-box-shadow: 2px 2px 2px 0;
  // box-shadow: 2px 2px 2px 0;
  // text-shadow: -1px -1px 0;
  // -webkit-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  // -moz-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  // -o-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  // transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
}
  
.helpcursor {
  cursor: help;
}

#search-input {
  display: inline-block;
  width: 100%;
  // @include box-sizing(content-box);
  margin: 0 0 6px 0;
  padding: 6px 12px;
  border: 1px solid;
  border-radius: 3px;
  -o-text-overflow: clip;
  text-overflow: clip;
}

#search-results {
  list-style-type: none;
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#search-results li {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
}

#search-results li:last-child {
  border-bottom: none;
}

#search-results a {
  text-decoration: none;
  color: #333;
}

#search-results a:hover {
  color: #0078d4;
}
